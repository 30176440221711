import React, { useEffect, useState } from "react";
import "../styles/SalesSection.css";

const services = [
  { 
    id: 1, 
    title: "VENTA DE HARDWARE", 
    description: "Notebooks, teclados, servidores, tablets y más."
  },
  { 
    id: 2, 
    title: "DISTRIBUCIÓN MAYORISTA", 
    description: "Soluciones para empresas con precios competitivos y logística optimizada."
  },
  { 
    id: 3, 
    title: "SOPORTE Y GARANTÍA", 
    description: "Asesoramiento en la compra y soporte técnico post-venta."
  },
];

const SalesSection = () => {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("sales");
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.75) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section id="sales" className={`sales-section ${isVisible ? "visible" : ""}`}>
      <div className={`sales-container ${isVisible ? "visible" : ""}`}>
        <h2>VENTAS Y DISTRIBUCIÓN</h2>
        <div className="cards-container">
          {services.map(service => (
            <div key={service.id} className="card">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SalesSection;
