import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeSection from "./components/HomeSection";
import BrandSection from "./components/BrandSection";
import SoftSection from "./components/SoftSection";
import SalesSection from "./components/SalesSection";
import ContactSection from "./components/ContactSection"; 
import WhatsAppButton from "./components/WhatsAppButton";
import "./styles/App.css";

const App = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <HomeSection />
        <BrandSection />    
        <SoftSection />
        <SalesSection />
        <ContactSection /> 
      </main>
      <Footer />
      <WhatsAppButton />
    </div>
  );
};

export default App;
