import React, { useEffect, useState } from "react";
import "../styles/SoftSection.css";

const services = [
  { 
    id: 1, 
    title: "DESARROLLO WEB", 
    description: "Creación de aplicaciones web modernas, escalables y seguras."
  },
  { 
    id: 2, 
    title: "APPS MÓVILES", 
    description: "Desarrollo de aplicaciones nativas y multiplataforma para Android e iOS."
  },
  { 
    id: 3, 
    title: "INTEGRACIONES Y API", 
    description: "Conectamos sistemas y automatizamos procesos con APIs eficientes."
  },
];

const SoftSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("soft");
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.75) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <section id="soft" className={`soft-section ${isVisible ? "visible" : ""}`}>
      <div className={`soft-container ${isVisible ? "visible" : ""}`}>
        <h2>DESARROLLO DE SOFTWARE</h2>
        <div className="cards-container">
          {services.map(service => (
            <div key={service.id} className="card">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SoftSection;
