import React, { useEffect, useState } from "react";
import "../styles/ContactSection.css";

const ContactSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("contact");
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.75) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section id="contact" className={`contact-section ${isVisible ? "visible" : ""}`}>
      <div className={`contact-container ${isVisible ? "visible" : ""}`}>
        <h2>CONTÁCTENOS</h2>
        <p className="contact-description">
          Complete sus datos y un asesor especializado se pondrá en contacto con usted a la brevedad.
        </p>
        <form className={`contact-form ${isVisible ? "visible" : ""}`}>
          <input type="text" placeholder="Nombre" className="contact-input" required />
          <input type="email" placeholder="Correo electrónico" className="contact-input" required />
          <textarea placeholder="Mensaje" className="contact-textarea" required></textarea>
          <button type="submit" className="contact-button">Enviar</button>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
