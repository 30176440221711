import React, { useEffect, useState } from "react";
import "../styles/BrandSection.css";

const services = [
  { 
    id: 1, 
    title: "MANUALES DE MARCA", 
    description: "Creamos tu identidad digital.",
    detdescription: "Diseñamos manuales de marca únicos y personalizados para fortalecer tu identidad visual."
  },
  { 
    id: 2, 
    title: "MERCHANDISING PERSONALIZADO", 
    description: "Fabricación de merchandising empresarial y regalos corporativos.",
    detdescription: "Creación y diseño de merchandising único para que tu empresa deje una huella."
  },
  { 
    id: 3, 
    title: "COMUNICACIÓN EMPRESARIAL", 
    description: "Asesoramos y guiamos en comunicación institucional, presencia digital en redes sociales y organización de eventos.",
    detdescription: "Guiamos tu estrategia de comunicación para reforzar tu presencia en el mercado."
  },
];

const BrandSection = () => {


  const [isVisible, setIsVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
      const [modalContent, setModalContent] = useState({ title: "", text: "" });
  
      // Función para abrir el modal con contenido dinámico
      const openModal = (title, text) => {
          setModalContent({ title, text });
          setModalOpen(true);
      };
  
      // Función para cerrar el modal
      const closeModal = () => {
          setModalOpen(false);
      };
      // Detecta la tecla ESC para cerrar el modal
      useEffect(() => {
          const handleKeyDown = (event) => {
              if (event.key === "Escape") {
                  closeModal();
              }
          };
  
          window.addEventListener("keydown", handleKeyDown);
          return () => window.removeEventListener("keydown", handleKeyDown);
      }, []);
  

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("brand");
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.75) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <section id="brand" className={`brand-section ${isVisible ? "visible" : ""}`}>
      <div className={`brand-container ${isVisible ? "visible" : ""}`}>
        <h2>DESARROLLO DE MARCA</h2>
        <div className="cards-container">
          {services.map(service => (
            <div key={service.id} className="card"
              onClick={() => openModal(service.title, service.detdescription)} // Agregar evento click
              >
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
       {/* Modal */}
       {modalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h2>{modalContent.title}</h2>
                        <p>{modalContent.text}</p>
                        <button className="close-btn" onClick={closeModal}>Cerrar</button>
                    </div>
                </div>
            )}
    </section>
  );
};


export default BrandSection;
