import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "../styles/Carousel.css";

const phrases = [
  "Desarrollamos software que impulsa tu negocio 🚀",
  "Transformamos ideas en soluciones tecnológicas 💡",
  "Infraestructura IT y equipamiento para empresas 🖥️",
  "Automatización y digitalización para mejorar tu productividad ⚡",
  "HDEVS | Innovación y tecnología para empresas 💼",
];

const Carousel = () => {
  return (
    <div className="carousel-container">
      <Swiper
        modules={[Autoplay, EffectFade]}
        effect="fade"
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        loop={true}
        className="swiper-container"
      >
        {phrases.map((phrase, index) => (
          <SwiperSlide key={index}>
            <div className="slide-content">{phrase}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;

