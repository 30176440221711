import React, { useEffect, useState } from "react";
import "../styles/Header.css";

const Header = () => {
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["home", "brand", "soft", "sales", "contact"];
      let currentSection = "";

      sections.forEach((id) => {
        const section = document.getElementById(id);
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top < window.innerHeight * 0.5 && rect.bottom > 0) {
            currentSection = id;
          }
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
        const headerOffset = 40; // Ajusta según el alto de tu header
        const sectionTop = section.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = sectionTop - headerOffset;

        // Si la sección ya está en la posición correcta, no hace nada
        if (Math.abs(window.scrollY - offsetPosition) < 2) {
            return;
        }

        // Hacer scroll al lugar correcto
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
};



  return (
    <header className="sticky-header">
      <nav className="nav-links">
        <span className={activeSection === "home" ? "active" : ""} onClick={() => scrollToSection("Home")}>Inicio</span>
        <span className={activeSection === "brand" ? "active" : ""} onClick={() => scrollToSection("brand")}>Marca</span>
        <span className={activeSection === "soft" ? "active" : ""} onClick={() => scrollToSection("soft")}>Software</span>
        <span className={activeSection === "sales" ? "active" : ""} onClick={() => scrollToSection("sales")}>Insumos</span>
        <span className={activeSection === "contact" ? "active" : ""} onClick={() => scrollToSection("contact")}>Contacto</span>
      </nav>
    </header>
  );
};

export default Header;
