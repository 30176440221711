import React, { useState, useEffect } from "react";
import "../styles/HomeSection.css";
import logo from "../assets/logocolores.jpg"; // ✅ Asegúrate que la imagen esté en esta ruta
import Carousel from "./Carousel"; 

const HomeSection = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                closeModal();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, []);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <section id="Home" className="home-section">
            <div className="home-container">

                {/* Logo con Modal */}
                <div className="logo-wrapper" onClick={openModal}>
                    <img src={logo} alt="Logo HDEVS" className="logo-image" />
                </div>

                {/* Cards */}
                <div className="cards-container">
                    <div className="card" onClick={() => scrollToSection("brand")}>
                        <h3>DESARROLLO DE MARCA</h3>
                        <p>Potenciamos la identidad visual y estrategia de tu negocio.</p>
                    </div>
                    <div className="card" onClick={() => scrollToSection("soft")}>
                        <h3>DESARROLLO DE SOFTWARE</h3>
                        <p>Soluciones a medida para optimizar procesos y escalar tu empresa.</p>
                    </div>
                    <div className="card" onClick={() => scrollToSection("sales")}>
                        <h3>EQUIPOS E INSUMOS</h3>
                        <p>Venta y asesoría en hardware y software para tu negocio.</p>
                    </div>
                </div>

                {/* Carrusel */}
                <div className="carousel-wrapper">
                    <Carousel />
                </div>
            </div>

            {/* Modal */}
            {modalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h2>Sobre HDEVS</h2>
                        <p>Combinamos innovación y experiencia para ofrecer desarrollo de software a medida, infraestructura IT y consultoría tecnológica.</p>
                        <button className="close-btn" onClick={closeModal}>Cerrar</button>
                    </div>
                </div>
            )}
        </section>
    );
};

export default HomeSection;
